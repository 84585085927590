import React from "react";
import axios from "axios";
import BlogCard from "./blog-card";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import { BaseUrl } from "./api";
import "swiper/css/bundle";
import { OutlinePrimaryButton } from "../assets/static/js/Button";

const cookies = new Cookies();

class AllPost extends React.Component {
  constructor() {
    super();
    this.state = {
      allPosts: [],
      posts: [],
    };
    this._isMounted = false;

    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
      this.title = "titleUz";
      this.text = "textUz";
    } else {
      this.name = "nameRu";
      this.title = "titleRu";
      this.text = "textRu";
    }
  }

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    this._isMounted = true;
    axios.get(BaseUrl + "posts/").then((res) => {
      let posts = res.data.slice(0, 12);
      posts = posts.filter((el) => el.isActive === true);
      this.allPosts = res.data;
      this.allPosts = this.allPosts.filter((el) => el.isActive === true);
      this._isMounted &&
        this.setState({
          posts,
        });
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { t } = this.props;
    return (
      <div className="main">
        <section className="container">
          <div className="trend-products-info flex justify-content-center">
            <h1 className="text-title">{t("Blog.text")}</h1>
          </div>

          <div className="blogs pointer">
            {this.state.posts.map((post) => (
              <div className="allBlogs" key={post.id}>
                <BlogCard
                  id={post.id}
                  image={post.image}
                  nameUz={post[this.name]}
                  titleUz={post[this.title]}
                  textUz={post[this.text]}
                  createdAt={post.createdAt}
                />
              </div>
            ))}
          </div>
          {this.state.posts.length > 12 && (
            <div style={{marginTop: '58px'}} className="d-flex justify-content-center">
              <button
                className="d-flex align-items-center justify-content-center"
                onClick={() =>
                  this.setState({
                    posts: this.allPosts,
                  })
                }
                style={{backgroundColor: '#C89B71', color: '#fff', padding: '16px', outline: 'none', border: 'none', width: '176px', height: '46px'}}
                >
                  {t("downloadMore.text")} 
                  <svg style={{marginLeft: '8px'}} xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19" fill="none">
                    <path d="M14.5 11.75L7.5 17.75L0.5 11.75" stroke="white"/>
                    <path d="M7.5 17.25L7.5 0.25" stroke="white"/>
                  </svg>
                </button>
            </div>
          )}
        </section>
      </div>
    );
  }
}
export default withTranslation()(AllPost);
