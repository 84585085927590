import React from "react";

import axios from "axios";

import { BaseUrl } from "../common/api";
import { withTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { withRouter } from "react-router-dom";
import { PrimaryButton } from "../assets/static/js/Button";
import { t } from "i18next";
import CurrencyFormat from "react-currency-format";

class OrdersInfo extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state and class variables
    this.state = {
      data: [],
      items: [],
    };
  }
  async componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    this._isMounted = true;
    const id = this.props.match.params.id;
    // fetching user order details
    await axios.get(BaseUrl + "order-infos/" + id).then((res) => {
      this.setState({ data: res.data, items: res.data.order_items });
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { data, items } = this.state;
    return (
      <div className="main">
        <div className="container orderinfo d-flex flex-column">
          {/* rendering main info's of order  */}
          <div className="d-flex flex-wrap justify-content-between p-4 w-100">
            <div className="d-flex flex-column">
              <span>{t("Price.text")}:</span>
              <span className="bold">
                <CurrencyFormat
                  value={data.price}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={" " + t("suffix.text")}
                />
              </span>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex flex-nowrap mb-2">
                <span className="bold">{t("orderNumber.text")}:</span>
                <span> {data.id}</span>
              </div>
              <div className="d-flex flex-nowrap mb-2">
                <span className="bold">{t("orderStatus.text")}:</span>
                <span>
                  {" "}
                  {data.order_items &&
                    t("orderStatus.status." + data.order_status)}
                </span>
              </div>
            </div>
            <div className="d-flex flex-column mb-2">
              <span>{t("DeliveryPeriod.text")}:</span>
              <span className="bold"> {data.expected_delivery_date}</span>
            </div>
          </div>
          {/* starting iteration of orders */}
          <div className="horizontal-line"></div>
          {items.length > 0 && (
            <div className="d-flex flex-wrap justify-content-between p-4">
              {items.map((item) => (
                <div className=" item-info" key={item.id}>
                  <img src={item.product.image} alt="img" />
                  <div className="d-flex flex-column">
                    <span className="bold">{item.product.vId}</span>
                    <span>
                      {item.product.size.width +
                        " x " +
                        item.product.size.length}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* redirect button to home page */}
        <PrimaryButton
          onClick={() => {
            this.props.history.push("../");
          }}
          value={t("mainPage.text")}
          className="mx-auto"
        />
      </div>
    );
  }
}
export default withRouter(withTranslation()(OrdersInfo));
