/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import { withTranslation } from "react-i18next";
import { OutlineSecondaryButton } from "../assets/static/js/Button";
import CurrencyFormat from "react-currency-format";

class ProductCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div className="item-card">
        {this.props.isNew && (
          <div className="triangle-container">
            <div style={{ borderBottomColor: "#00C773"}} className="triangle"></div>
            <div className="triangle-container_text">New</div>
          </div>
        )}
        {this.props.isTrend && (
          <div className="triangle-container">
            <div style={{ borderBottomColor: "#FF8900"}} className="triangle"></div>
            <div className="triangle-container_text">Hit</div>
          </div>
        )}
        {this.props.isDiscount > 0 && (
          <div className="triangle-container">
            <div style={{ borderBottomColor: "#F00"}} className="triangle"></div>
            <div className="triangle-container_text">Sale</div>
          </div>
        )}
        <div style={{ maxHeight: "372px", overflow: "hidden" }}>
          <img src={this.props.image} alt={this.props.name} />
        </div>
        <div className="bottom-infos d-flex flex-column">
          <div className="small-infos d-flex flex-column">
            <h1 className="text-lg"> {this.props.name} </h1>
            <h1 className="text-xs">{this.props.articul}</h1>
            {this.props.models} {this.props.models ? t("models.text") : ""}
          </div>
          <div className="price-infos mt-1 d-flex flex-wrap">
            <div className="mb-3 d-flex flex-gap-5">
              {this.props.isDiscount > 0 && (
                <span className="text-sm text-through mr-2 text-nowrap" style={{ fontSize: "12px" }}>
                  <CurrencyFormat
                    value={this.props.price}
                    displayType={"text"}
                    thousandSeparator=" "
                    suffix={t("suffix.text")}
                  />
                </span>
              )}

              <br />
              <span className="text-sm bold text-nowrap">
                <CurrencyFormat
                  value={this.props.price - this.props.isDiscount}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={t("suffix.text")}
                />
              </span>
              {/* <span className="text-sm bold text-nowrap ml-2">m&sup2;</span>
              <span className="ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M9 7C8.42135 7 7.86639 7.21071 7.45722 7.58579C7.04805 7.96086 6.81818 8.46957 6.81818 9C6.81818 9.53043 7.04805 10.0391 7.45722 10.4142C7.86639 10.7893 8.42135 11 9 11C9.57865 11 10.1336 10.7893 10.5428 10.4142C10.9519 10.0391 11.1818 9.53043 11.1818 9C11.1818 8.46957 10.9519 7.96086 10.5428 7.58579C10.1336 7.21071 9.57865 7 9 7ZM9 12.3333C8.03558 12.3333 7.11065 11.9821 6.4287 11.357C5.74675 10.7319 5.36364 9.88406 5.36364 9C5.36364 8.11595 5.74675 7.2681 6.4287 6.64298C7.11065 6.01786 8.03558 5.66667 9 5.66667C9.96442 5.66667 10.8893 6.01786 11.5713 6.64298C12.2532 7.2681 12.6364 8.11595 12.6364 9C12.6364 9.88406 12.2532 10.7319 11.5713 11.357C10.8893 11.9821 9.96442 12.3333 9 12.3333ZM9 4C5.36364 4 2.25818 6.07333 1 9C2.25818 11.9267 5.36364 14 9 14C12.6364 14 15.7418 11.9267 17 9C15.7418 6.07333 12.6364 4 9 4Z" fill="#787878"/>
              </svg>
              </span>
              <span className="text-sm" style={{ fontSize: "12px" }}>12345</span> */}
            </div>
            {/* <div className="button-wrapper">
              <OutlineSecondaryButton value={t("more.text")} />
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(ProductCard);
