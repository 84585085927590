// export const BaseUrl = "http://localhost:8000/api/v1/";
// export const BaseUrl = "https://www.sagex.uz/api/v1/";
// export const BaseUrl = "http://176.96.243.117:8000/api/v1/";
// export const BaseUrl = "http://185.74.5.116:8888/api/v1/";
// export const BaseUrl = "https://6498-84-54-69-66.ngrok-free.app/api/v1"
export const BaseUrl = "https://www.sagexpress.uz/api/v1/";






