import Skeleton from "react-loading-skeleton";

const ProductCardSkeleton = ({ cards }) => {
  return Array(cards)
    .fill()
    .map((_, index) => (
      <div className="item-card-skeleton" key={index}>
        <div className="card-image-skeleton">
          <Skeleton className="image-skeleton" height={370}  />
          <Skeleton className="small-infos-skeleton" />
        </div>
        <div className="bottom-skeleton">
          <div className="left-skeleton">
            <Skeleton width={130} height={40} />
          </div>
          <div className="right-skeleton">
            <Skeleton width={100} height={50}  />
          </div>
        </div>
      </div>
    ));
};
export default ProductCardSkeleton;
