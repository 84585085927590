import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { BaseUrl } from "./api";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import { PrimaryButton, SecondaryButton } from "../assets/static/js/Button";

const cookies = new Cookies();

class Survey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: [],
    };
    this._isMounted = false;
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
    } else {
      this.name = "nameRu";
    }
    this.getData = this.getData.bind(this);
    this.data = {};
    this.post_data = [];
    this.jsonObj = {};
  }
  componentDidMount() {
    this._isMounted = true;
    axios
      .get(BaseUrl + "clientsurvery/")
      .then((res) => {
        const surveys = res.data;
        this._isMounted &&
          this.setState({
            surveys,
          });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.message);
        console.log(err.response);
      });
  }
  getText = (data, e) => {
    this.data[data] = e.target.value;
  };
  getData = (row, data, e) => {
    if (this.data[data]) {
      this.result = this.data[data];
    } else {
      this.result = [];
    }
    if (e.target.type !== "checkbox") {
      this.data[data] = row;
    } else {
      if (e.target.checked) {
        this.result.push(row);
      } else {
        this.result.splice(this.result.indexOf(row), 1);
      }
      this.data[data] = this.result;
    }
  };
  toJson = () => {
    Object.keys(this.data).forEach((key) => {
      this.jsonObj["question"] = key;
      this.jsonObj["answer"] = this.data[key];
      this.post_data.push(this.jsonObj);
      this.jsonObj = {};
    });
    let formData = new FormData();
    formData.append("user_answers", this.post_data);
    axios
      .post(
        BaseUrl + "useranswers/",
        {
          user_answers: this.post_data,
        },
        {
          headers: {
            accept: "application/json",
          },
        }
      )
      .then((res) => console.log(res.data))
      .catch((err) => {
        console.log(err);
        console.log(err.message);
        console.log(err.response);
      });
    this.props.hideModal();
  };
  render() {
    return (
      <div>
        {this.state.surveys.map((survey) => (
          <div key={survey.id} className="survey">
            <h2 className="bold text-lg">{survey[this.name]}</h2>
            <hr className="mt-2" />
            <div className="survey-container">
              {survey.questions.map((question, index) => (
                <div className="single-survey" key={question.id}>
                  <h3 className="survey-title bold text-lg">
                    {question[this.name]}
                  </h3>
                  {question.type_question === "text" && (
                    <div className="option-container">
                      <div className="text-input">
                        <input
                          className="base-input bg-light"
                          type="text"
                          onChange={(e) => this.getText(question.nameUz, e)}
                        />
                      </div>
                    </div>
                  )}

                  <div>
                    <div className="option-container">
                      {question.answers.map((answer) => (
                        <div key={answer.id}>
                          <label
                            htmlFor={question.nameUz + answer.id}
                            className={
                              question.type_question === "radio"
                                ? "single-option radio-container"
                                : question.type_question === "checkbox"
                                ? "single-option checkbox-container"
                                : ""
                            }
                          >
                            <input
                              id={question.nameUz + answer.id}
                              type={question.type_question}
                              name={question.id}
                              onChange={(e) =>
                                this.getData(answer.nameUz, question.id, e)
                              }
                            />
                            <span
                              className={
                                question.type_question === "radio"
                                  ? "radiomark"
                                  : question.type_question === "checkbox"
                                  ? "checkmark"
                                  : ""
                              }
                            ></span>
                            <span
                              className={
                                question.type_question === "radio"
                                  ? "radio-title text-sm"
                                  : question.type_question === "checkbox"
                                  ? "checktitle text-sm"
                                  : ""
                              }
                            >
                              {answer[this.name]}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* {question.type_question !== "text" && (
                  <label htmlFor="">
                    {t("other.text")}:
                    <input type="text" />
                  </label>
                )} */}
                </div>
              ))}
            </div>
            <hr className="mt-2" />
          </div>
        ))}
        <div className="modal-buttons pt-2">
          <PrimaryButton onClick={() => this.toJson()} value={t("rate.text")} />
          <SecondaryButton
            onClick={() => this.props.hideModal()}
            value={t("rateLater.text")}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(withTranslation()(Survey));
