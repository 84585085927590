/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/static/logo.svg";
import { useHistory, withRouter } from "react-router-dom";

import Cookies from "universal-cookie";
import axios from "axios";
import { connect } from "react-redux";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import {Popover, Overlay} from "react-bootstrap"
import ProgressBar from "./ProgressBar";



import { BaseUrl } from "./api";

const current = new Date();
var nextYear = new Date();
nextYear.setFullYear(current.getFullYear() + 1);

function withParams(Component) {
  return (props) => <Component {...props} history={useHistory()} />;
}
const cookies = new Cookies();
const lngs = [
  { code: "uz", nativeName: "O`z" },
  { code: "ru", nativeName: "Ру" },
];
class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogs: [],
      cart: [],
      search: "",
      show: false,
      regions: [],
      region: "",
      lngShow: false,
      query: "",
      elements: false,
      target: null,
      target_show: false,
      progress: 0,
      region_id: 0,
      isClosing: false
    };
    this._isMounted = false;
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
      this.language = "O`z";
    } else {
      this.name = "nameRu";
      this.language = "Ру";
    }
    this.showModal = this.showModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.modalRef = React.createRef();
    this.buttonRef = React.createRef();

    this.length = 0;
  }
  async componentDidMount() {
    this._isMounted = true;
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    let data = [];
    document.addEventListener('mousedown', this.handleClickOutside);
    let region = "Hudud";
    await axios.get(BaseUrl + "categories/active").then((res) => {
      data = res.data;
      this._isMounted &&
        this.setState({
          catalogs: data,
        });
    })
    await axios.get(BaseUrl + "regions/").then((res) => {
      this.regions = res.data;
      this._isMounted &&
        this.setState({
          regions: this.regions,
        });
    });
    if (cookies.get("Region")) {
      let id = parseInt(cookies.get("Region"));
      let reg = this.regions.find((item) => item.id === id);
      region = reg[this.name];
      this.setState({
        region_id: id,
      })
    } else {
      region = this.regions[0][this.name];
      // id = this.regions[0].id;
      // this.setState({
      //   region_id: id,
      // })
      this.interval = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.progress >= 100) {
            this.setYesButton();
            clearInterval(this.interval);
            return { progress: 100 };
          }
          cookies.set("Region", this.regions[0].id, {expires: nextYear});
          return { progress: prevState.progress + 1 };
        });
      }, 100);
     

      this._isMounted &&
        this.setState({
          target_show: true,
          target: this.buttonRef.current
        });
    }
    if (cookies.get("Cart")) {
      data = cookies.get("Cart");
    }
    this._isMounted &&
      this.setState({
        cart: data.length,
        region,
      });
      this.unlisten = this.props.history.listen(() => {
        if (this.state.show) {
          this.hideModal();
        }
      });
  }

  setYesButton = () => {
    this.setState({ target_show: false });
    cookies.set("Region", this.regions[0].id,  {expires: nextYear});
  }
  componentDidUpdate(prevProps) {
    if (this.props.parentData !== prevProps.parentData) {
      this._isMounted = true;
      this._isMounted &&
        this.setState(
          {
            cart: this.props.parentData,
          }
        );
    }
  }
  showModal = () => {
    document.body.style.overflow = 'hidden';
    this._isMounted &&
      this.setState(
        {
          show: true,
        }
      );
  };
  hideModal = () => {
    document.body.style.overflow = 'visible';
    this._isMounted &&
      this.setState({
        show: false,
      });
  };
  handleClick = (e, id) => {
    e.preventDefault();
    cookies.set("Region", id, { expires: nextYear });
    const reg = this.state.regions.find((item) => item.id === id);  
    this._isMounted &&
      this.setState(
        {
          region: reg.name,
          region_id: id,
        }
      );
    this.hideModal();
    window.location.reload(false);
  };
  handleLanguage = () => {
    const { i18n } = this.props;
    const currentLang = i18n.language;
    const changedLang = currentLang === "uz" ? "ru" : "uz";
    cookies.set("Language", changedLang, {expires: nextYear});
    i18n.changeLanguage(changedLang).then(() => {
      window.location.reload();
    });
  };
  getQuery = (e) => {
    if (e.key === "Enter") {
      this.props.history.push(`/searcht?offset=0&query=` + e.target.value);
    }
  };
  // closeContent = () => {
  //   this._isMounted &&
  //     this.setState({
  //       elements: false,
  //     });
  // };

  handleClickOutside = (event) => {
    if (this.modalRef.current && !this.modalRef.current.contains(event.target) && this.state.show) {
      document.body.style.overflow = 'visible';
      this.setState({ show: false });
    }
  };

  closeContent = () => {
    this.setState({ isClosing: true });
    setTimeout(() => {
      this.setState({ elements: false, isClosing: false });
    }, 700);
  }


  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval);
    document.removeEventListener('mousedown', this.handleClickOutside);
    if (this.unlisten) {
      this.unlisten();
    }
  }
  render() {
    const { t } = this.props;
    const history = this.props.history;
    const { isClosing } = this.state;
    return (
      <div className="header container">
        <header>
          <div className="bottom-header">
          <div className="pointer location"  onClick={() => this.showModal()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M7.5 7.175C7.07376 7.175 6.66498 7.0038 6.36358 6.69905C6.06218 6.3943 5.89286 5.98098 5.89286 5.55C5.89286 5.11902 6.06218 4.7057 6.36358 4.40095C6.66498 4.0962 7.07376 3.925 7.5 3.925C7.92624 3.925 8.33502 4.0962 8.63642 4.40095C8.93782 4.7057 9.10714 5.11902 9.10714 5.55C9.10714 5.7634 9.06557 5.97471 8.98481 6.17186C8.90404 6.36901 8.78566 6.54815 8.63642 6.69905C8.48718 6.84994 8.31001 6.96964 8.11503 7.0513C7.92004 7.13297 7.71105 7.175 7.5 7.175ZM7.5 1C6.30653 1 5.16193 1.47937 4.31802 2.33266C3.47411 3.18595 3 4.34326 3 5.55C3 8.9625 7.5 14 7.5 14C7.5 14 12 8.9625 12 5.55C12 4.34326 11.5259 3.18595 10.682 2.33266C9.83807 1.47937 8.69347 1 7.5 1Z" fill="white"/>
                  </svg>
                  <span style={{ margin: "0 4px", color: "white" }}>
                    {" " + this.state.region}
                  </span>
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                    <path d="M8 1L4.5 5L1 1" stroke="white"/>
                  </svg> */}
          </div>
                <div className="dropdown">
                  <button
                    className="dropbtn"
                    onClick={this.handleLanguage}
                  >
                    {this.language}
                  </button>
                  {/* <div
                    className={ "dropdown-content"}
                  >
                    {lngs.map((lng) => {
                      return (
                        <p
                          className=" p-2  language"
                          key={lng.code}
                          type="submit"
                          onClick={() => this.handleLanguage(lng.code)}
                        >
                          {lng.nativeName}
                        </p>
                      );
                    })}
                  </div> */}
                </div>
          </div>
          <div>
            <section>
              <div
                id="myModal"
                className={this.state.show ? `mod modalOpen` : "mod"}
              >
                <div className="modalContent flex center" ref={this.modalRef}>
                <button className="close-button item-button" style={{backgroundColor:"transparent"}} onClick={() => this.hideModal()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path d="M1 1L21 21" stroke="#AFB3BD"/>
                  <path d="M1 21L21 1" stroke="#AFB3BD"/>
                </svg>
                </button>
                  <h1 className="text-title">{t("choseLocation.text")}</h1>

                  <p className="pt-1 text">{t("availableShops.text")}:</p>
                  <div className="regions-container mt-2">
                    {this.state.regions
                      .filter((el) => el.isActive === true)
                      .map((item) => (
                        <div key={item.id} className="region-container">
                          <label htmlFor={item.id + "region"}  className="region-label d-flex justify-content-between align-items-center" style={{ width: "100%"}}>
                            {item[this.name]}
                          <input id={item.id + "region"}
                           checked={this.state.region_id === item.id} 
                           onChange={(e, id) => this.handleClick(e, item.id)}
                           name="regions" type="radio" />
                          
                          </label>
                        </div>
                        // <button
                        //   key={item.id}
                        //   onClick={(e) => this.handleClick(e)}
                        //   name={item.id}
                        //   className="region"
                        // >
                          
                        // </button>
                      ))}
                  </div>

                  <hr className="mt-2" />
                  <p className="pt-1 text">{t("sagSoon.text")}:</p>
                  <div className="regions-container mt-2">
                    {this.state.regions
                      .filter((el) => el.isActive === false)
                      .map((item) => (
                        <div key={item.id} className="region-container">
                          <label htmlFor={item.id}   className="region-label d-flex justify-content-between align-items-center" style={{ width: "100%"}}>
                            {item[this.name]}
                          </label>
                      </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* <form className="col-5">  */}
              <div className="left-header">
                <div className="pointer"
                style={{position:"relative"}}
                >
                  <Link to="/">
                    <div id="header-logo"
                    ref={this.buttonRef}
                    
                    >
                    </div>
                  </Link>

                  <Overlay target={this.buttonRef} show={this.state.target_show} placement="bottom">
                    <Popover id="popover-basic" style={{ position: "relative", top: "0px", borderRadius: 0, padding: '8px 8px 8px 8px'}}>
                      <Popover.Title style={{ backgroundColor: '#fff', border: 0, marginBottom: '4px', marginTop: '5px'}}>{t("yourcity.text")}{this.state.region}?</Popover.Title>
                      <ProgressBar  />
                      <Popover.Content style={{marginTop: '8px', marginBottom: '2px'}} className="modal-buttons">
                      <button className=" btn-secondary" style={{backgroundColor: "#0942C3", color: "#fff", borderRadius: 0, padding: '4px 8px 4px 8px'}} onClick={this.setYesButton} type="button">Да</button>
                      <button className=" btn-secondary" style={{backgroundColor: "#E3E3E3", color: "#000", borderRadius: 0, padding: '4px 8px 4px 8px'}} onClick={() => this.setState({target_show: false, show: true })} type="button">Нет</button>
{/*                        
                          <div className="col-6 d-flex justify-content-start">
                            <button className=" btn-primary" onClick={() => this.setState({ target_show: false })} type="button">Yes</button>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                            <button className=" btn-secondary" onClick={() => this.setState({target_show: false, show: true })} type="button">No</button>
                          </div> */}
                      </Popover.Content>
                    </Popover>
                  </Overlay>
                </div>
                
              </div>
              <div className="middle-header">
                <div className="dropdown">
                  <div 
                      className="dropbtn services"
                      onClick={() => {
                        this._isMounted && this.setState({ lngShow: !this.state.lngShow });
                      }}
                      onMouseEnter={() => {
                        this._isMounted && this.setState({ lngShow: true });
                      }}
                      onMouseLeave={() => {
                        this._isMounted && this.setState({ lngShow: false });
                      }}
                    >
                    {t("catalog.text")}
                  </div>
                  <div
                    className={
                      this.state.lngShow
                        ? "dropdown-content show"
                        : "dropdown-content"
                    }
                    onMouseEnter={() => {
                      this._isMounted && this.setState({ lngShow: true });
                    }}
                    onMouseLeave={() => {
                      this._isMounted && this.setState({ lngShow: false });
                    }}
                  >
                    {this.state.catalogs.map((lng) => {
                      return (
                        <p
                          className=" p-2  language"
                          key={lng.id}
                          type="submit"
                          onClick={() => {
                            this.props.history.push(`/category?category_id=${lng.id}&nameUz=${lng.nameUz}&nameRu=${lng.nameRu}`);
                            this.closeContent();
                          }}
                        >
                          {lng[this.name]}
                        </p>
                      );
                    })}
                  </div>
                </div>
                 
                
                  <Link to="/contacts" >
                  <div className="services">
                    {t("shops.text")}
                  </div>
                  </Link>
                  <Link to="/blogs">
                  <div className="services">
                    {t("Blog.text")}
                  </div>
                  </Link>
                  <Link to="/about" >
                    <div className="services">
                        {(t("AboutUs.text"))}
                    </div>
                  </Link>
                  <Link to="/method">
                    <div className="services">
                      SAG method
                    </div>
                  </Link>
              </div>
              <div className="right-header">
              
                
                {/* <span className="vertical-line"></span> */}
                
                <div className="search-input">
                  <div className="search-icon"></div>
                  <div style={{ textAlign: 'right' }}>
                    <input
                      onKeyDown={(e) => this.getQuery(e)}
                      onChange={(e) =>
                        this._isMounted &&
                        this.setState({ query: e.target.value })
                      }
                      className={this.state.query.length > 0 ? "focus" : ""}
                      type="search"
                      value={this.state.query}
                      placeholder={t("searchPlaceholder.text")}
                    />
                  </div>
                </div>
                <div
                  className="cart pointer"
                  onClick={() => history.push("/cart")}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="17" viewBox="0 0 22 17" fill="none">
                      <path d="M21.9918 5.50434H17.4989L14.6938 0.0332031L13.6256 0.675314L16.1045 5.51314H5.89548L8.37435 0.675314L7.30615 0.0332031L4.50111 5.50434H0V6.82375H1.51668L4.63973 15.3559C4.78651 15.7693 5.15345 16.0332 5.56116 16.0332H16.4388C16.8466 16.0332 17.2135 15.7693 17.3603 15.3559L20.4833 6.82375H22V5.50434H21.9918Z" fill="white"/>
                  </svg>
                  {this.props.numberCart !== 0 && (
                    <span className="basket-badge text-xs">
                      {this.props.numberCart}
                    </span>
                  )}
                </div>
                <div
                  className="bar-menu pointer"
                  onClick={() =>
                    this._isMounted &&
                    this.setState({
                      elements: true,
                    })
                  }
                >
                  {/* <i className="fa-solid fa-bars fa-2xl"></i> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <g clipPath="url(#clip0_1288_6329)">
                      <path d="M2 4.0332H22" stroke="#01091C" strokeLinecap="square" strokeLinejoin="round"/>
                      <path d="M2 12.0332H22" stroke="#01091C" strokeLinecap="square" strokeLinejoin="round"/>
                      <path d="M2 20.0332H22" stroke="#01091C" strokeLinecap="square" strokeLinejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1288_6329">
                        <rect width="24" height="24" fill="white" transform="translate(0 0.0332031)"/>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </section>
          </div>
          {this.state.elements === true && (
            <div className={`elements ${isClosing ? 'closing' : ''}`}>
              <div className="open-content text-lg">
                <div
                  className="close-icon container"
                  style={{ fontSize: "30px", paddingTop: "15px" }}
                  onClick={() => this.closeContent()}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_880_2263)">
                      <path d="M3.59961 3.42578L20.5444 20.3706" stroke="#01091C" strokeLinecap="square" strokeLinejoin="round"/>
                      <path d="M3.47656 20.5723L20.6652 3.38361" stroke="#01091C" strokeLinecap="square" strokeLinejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_880_2263">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <br/>
                <br/>
                <div className="container" style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="pointer d-flex align-items-center"
                    style={{ marginRight: "20px" }}
                    onClick={() => this.closeContent()}
                  >
                    {/* <i className="fa-regular fa-map fa-sm icon"></i> */}
                    <svg style={{ marginRight: '4px'}} xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17" fill="none">
                      <path d="M6.375 7.96875C5.8422 7.96875 5.33122 7.75474 4.95447 7.37381C4.57773 6.99288 4.36607 6.47622 4.36607 5.9375C4.36607 5.39878 4.57773 4.88212 4.95447 4.50119C5.33122 4.12026 5.8422 3.90625 6.375 3.90625C6.9078 3.90625 7.41878 4.12026 7.79553 4.50119C8.17227 4.88212 8.38393 5.39878 8.38393 5.9375C8.38393 6.20425 8.33197 6.46838 8.23101 6.71483C8.13005 6.96127 7.98207 7.18519 7.79553 7.37381C7.60898 7.56243 7.38752 7.71205 7.14378 7.81413C6.90005 7.91621 6.63882 7.96875 6.375 7.96875ZM6.375 0.25C4.88316 0.25 3.45242 0.849217 2.39752 1.91583C1.34263 2.98244 0.75 4.42908 0.75 5.9375C0.75 10.2031 6.375 16.5 6.375 16.5C6.375 16.5 12 10.2031 12 5.9375C12 4.42908 11.4074 2.98244 10.3525 1.91583C9.29758 0.849217 7.86684 0.25 6.375 0.25Z" fill="#0942C3"/>
                    </svg>
                    <span onClick={() => this.showModal()}>
                      {" " + this.state.region}
                    </span>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                      <path d="M12 1L6.5 7L1 1" stroke="#0942C3"/>
                    </svg> */}
                  </div>
                  <button
                    className="dropbtn text-lg"
                    onClick={() => this.handleLanguage(this.state.lng)}
                  >
                    {/* <i className="fa-solid fa-earth-europe px-1 icon"></i> */}
                    {this.language}
                  </button>
                </div>
                <hr/>
                {/* <div className="pointer container" onClick={() => this.closeContent()}>
                  <Link to="/about">{(t("AboutUs.text"))}</Link>
                </div> */}
                <div className="pointer container" onClick={() => this.closeContent()}>
                  <Link to="/contacts" >
                      {t("shops.text")}
                  </Link>
                </div><div className="pointer container" onClick={() => this.closeContent()}>
                  <Link to="/blogs">{t("Blog.text")}</Link>
                </div><div className="pointer container" onClick={() => this.closeContent()}>
                  <Link to="/about">{(t("AboutUs.text"))}</Link>
                </div><div className="pointer container" onClick={() => this.closeContent()}>
                  <Link to="/method"> SAG method</Link>
                </div>
                
              </div>
            </div>
          )}
        </header>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    numberCart: state._todoProduct.numberCart,
  };
};
export default withRouter(withParams(
  connect(mapStateToProps, null)(withTranslation()(AppHeader))
));
