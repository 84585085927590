import React from "react";
import Cookies from "universal-cookie";
import CurrencyFormat from "react-currency-format";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import axios from "axios";
import { BaseUrl } from "./api";
import { PrimaryButton, SecondaryButton } from "../assets/static/js/Button";

const cookies = new Cookies();

class OrderCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      price: 0,
      data: [],
      discount: 0,
      warehouses: [],
      take: 0,
      warehouse: "",
    };
    this._isMounted = false;

    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "name_uz";
    } else {
      this.name = "name_ru";
    }
  }
  componentDidMount() {
    this._isMounted = true;
    let data = this.props.cart;
    let region = cookies.get("Region");
    if (cookies.get("Cart")) {
      data = cookies.get("Cart");
    }

    axios.get(BaseUrl + "warehouse/" + region).then((res) => {
      const warehouses = res.data;
      this._isMounted &&
        this.setState({
          price: this.props.dataParentToChild,
          discount: this.props.discount,
          data,
          warehouses,
        });
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.dataParentToChild !== prevProps.dataParentToChild) {
      this._isMounted &&
        this.setState({
          price: this.props.dataParentToChild,
          discount: this.props.discount,
        });
    }
  }

  takeAway = () => {
    if (this.state.take === 0) {
      this.setState({
        take: 1,
      });
    } else if (this.state.warehouse.length > 0) {
      this.props.history.push(`/checkout?baseName=${this.state.warehouse}`);
    }
  };

  goBack = () => {
    if (this.state.take === 0) {
      this.props.history.push("/checkout");
    } else {
      this.setState({
        take: 0,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      warehouse: e.target.value,
    });
  };
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { t } = this.props;

    return (
      <div className="p-3">
        <h4 className="text-center bold">{t("yourOrder.text")}</h4>
        <div className="check-info text-sm">
          <span className="mr-2">{t("Price.text")}:</span>
          <span className="bold">
            <CurrencyFormat
              value={this.state.price}
              displayType={"text"}
              thousandSeparator=" "
              suffix={" " + t("suffix.text")}
            />
          </span>
        </div>
        <div className="check-info text-sm">
          <span className="mr-2">{t("discount.text")}:</span>
          <span className="bold">
            {" "}
            <CurrencyFormat
              value={this.state.discount}
              displayType={"text"}
              thousandSeparator=" "
              suffix={" " + t("suffix.text")}
            />
          </span>
        </div>
        <div className="check-info text-sm">
          <span className="mr-2">{t("DeliveryPeriod.text")}:</span>
          <span className="bold">
            {this.props.delivery_day > 0 ? this.props.delivery_day : "0"}{" "}
            {t("singleDay.text")}
          </span>
        </div>
        {/* <div className="check-info text-sm">
          <span className="mr-2">{t("deliveryPrice.text")}:</span>
          <span className="bold">
            <CurrencyFormat
              value={this.props.delivery_price}
              displayType={"text"}
              thousandSeparator=" "
              suffix={" " + t("suffix.text")}
            />
          </span>
        </div> */}
        <hr/>
        <div className="check-info text-sm">
          <span className="mr-2">{t("all.text")}:</span>
          <span className="bold">
            <CurrencyFormat
              value={
                this.state.price -
                this.state.discount +
                this.props.delivery_price
              }
              displayType={"text"}
              thousandSeparator=" "
              suffix={" " + t("suffix.text")}
            />
          </span>
        </div>
        {this.state.take === 1 && (
          <div className="flex flex-column">
            <label className="text-sm text-medium">Sklad</label>
            <select
              className="base-input pointer"
              as="select"
              onChange={this.handleChange}
              name="skald"
            >
              <option selected disabled>
                {t("chose.text")}
              </option>

              {this.state.warehouses.map((item) => (
                <option value={item[this.name]} key={item.id}>
                  {item.name_uz}
                </option>
              ))}
            </select>
          </div>
        )}

        {this.props.button !== 0 && this.state.price !== 0 && (
          <div className="check-info check-buttons">
            {this.state.take === 0 ? (
              <PrimaryButton
                onClick={() => this.goBack()}
                value={t("getOrder.text")}
              />
            ) : (
              <SecondaryButton
                onClick={() => this.goBack()}
                value={t("back.text")}
              />
            )}

            {this.state.take === 0 ? (
              <SecondaryButton
                onClick={() => this.takeAway()}
                value={t("carry.text")}
              />
            ) : (
              <PrimaryButton
                onClick={() => this.takeAway()}
                value={t("carry.text")}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(withTranslation()(OrderCard));
