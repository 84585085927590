import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BaseUrl } from "./api";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";

import "swiper/css/bundle";

const cookies = new Cookies();

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class Post extends React.Component {
  constructor() {
    super();
    this.state = {
      post: [],
      tooltipText: "Copy to clipboard",
    };
    this._isMounted = false;

    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
      this.title = "titleUz";
      this.text = "textUz";
    } else {
      this.name = "nameRu";
      this.title = "titleRu";
      this.text = "textRu";
    }
  }

  openInNewWindow = (url) => {
    const width = 600;
    const height = 500;
    const left = (window.outerWidth - width) / 2;
    const top = (window.outerHeight - height) / 2;
    window.open(
      url,
      "_blank",
      `noopener,noreferrer,width=${width},height=${height},left=${left},top=${top}`
    );
  };

  copyUrlToClipboard = () => {
    const url = window.location.href;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(url).then(() => {
        this.setState({ tooltipText: "Copied!" });
      });
    } else {
      this.setState({
        tooltipText: "Your browser does not support automatic copying.",
      });
    }
  };

  resetTooltipText = () => {
    this.setState({ tooltipText: "Copy to clipboard" });
  };

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    this._isMounted = true;
    let { id } = this.props.params;
    axios.get(BaseUrl + `posts/${id}/`).then((res) => {
      let post = res.data;

      this.data = post.createdAt.slice(0, 10);
      this._isMounted && this.setState({ post });
      document.title = this.state.post[this.title]
        ? this.state.post[this.title]
        : "SAG Express";
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const post = this.state.post;
    const currentUrl = encodeURIComponent(window.location.href);
    const { t } = this.props;

    return (
      <div className="main" style={{ marginBottom: "198px" }}>
        <div
          className="container"
          style={{ marginTop: "32px", marginBottom: "80px" }}
        >
          <div
            className="d-flex align-items-center pointer"
            onClick={() => window.history.back()}
          >
            <svg
              style={{ marginRight: "12px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="18"
              viewBox="0 0 27 18"
              fill="none"
            >
              <path
                d="M10 1L2 9.00006L10 17.0001"
                stroke="#01091C"
                strokeWidth="1.5"
              />
              <path d="M2.5 9H27" stroke="#01091C" strokeWidth="1.5" />
            </svg>
            <h4 style={{ marginBottom: "0" }}>{t("goBack.text")}</h4>
          </div>
        </div>
        <div className="container d-flex justify-content-center">
          <div style={{ backgroundColor: "white" }}>
            <div className="blog-image">
              <img src={post.image} alt={post.seoTitleUz} />
            </div>
            <div
              style={{
                paddingLeft: "24px",
                paddingRight: "24px",
                marginTop: "48px",
              }}
            >
              <h1 style={{ marginBottom: "16px" }} className="text-title">
                {post[this.title]}
              </h1>
              <p style={{ color: "#787878" }}>
                {post.textUz && ReactHtmlParser(post[this.text])}
              </p>
              <div className="blog-date d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <p style={{ marginRight: "32px" }}>{this.data}</p>
                  <svg
                    className="pointer"
                    onClick={() =>
                      this.openInNewWindow(
                        `https://t.me/share/url?url=${currentUrl}`
                      )
                    }
                    style={{ marginRight: "16px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_1689_122)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.777 3.43C20.0241 3.32599 20.2946 3.29011 20.5603 3.32611C20.826 3.36211 21.0772 3.46866 21.2877 3.63468C21.4982 3.8007 21.6604 4.02011 21.7574 4.27008C21.8543 4.52005 21.8825 4.79144 21.839 5.056L19.571 18.813C19.351 20.14 17.895 20.901 16.678 20.24C15.66 19.687 14.148 18.835 12.788 17.946C12.108 17.501 10.025 16.076 10.281 15.062C10.501 14.195 14.001 10.937 16.001 9C16.786 8.239 16.428 7.8 15.501 8.5C13.199 10.238 9.50302 12.881 8.28102 13.625C7.20302 14.281 6.64102 14.393 5.96902 14.281C4.74302 14.077 3.60602 13.761 2.67802 13.376C1.42402 12.856 1.48502 11.132 2.67702 10.63L19.777 3.43Z"
                        fill="#C89B71"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1689_122">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div
                    className="tooltip-container pointer"
                    onMouseLeave={this.resetTooltipText}
                  >
                    <svg
                      onClick={() => this.copyUrlToClipboard()}
                      width="31"
                      height="11"
                      viewBox="0 0 31 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0667 1H5.5C3.01472 1 1 3.01472 1 5.5V5.5C1 7.98528 3.01472 10 5.5 10H13.5C15.9853 10 18 7.98528 18 5.5V5.5"
                        stroke="#C89B71"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                      />
                      <path
                        d="M20.9333 10H25.5C27.9853 10 30 7.98528 30 5.5V5.5C30 3.01472 27.9853 1 25.5 1H17.5C15.0147 1 13 3.01472 13 5.5V5.5"
                        stroke="#C89B71"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                      />
                    </svg>
                    <span className="tooltip-text">
                      {this.state.tooltipText}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M9 7C8.42135 7 7.86639 7.21071 7.45722 7.58579C7.04805 7.96086 6.81818 8.46957 6.81818 9C6.81818 9.53043 7.04805 10.0391 7.45722 10.4142C7.86639 10.7893 8.42135 11 9 11C9.57865 11 10.1336 10.7893 10.5428 10.4142C10.9519 10.0391 11.1818 9.53043 11.1818 9C11.1818 8.46957 10.9519 7.96086 10.5428 7.58579C10.1336 7.21071 9.57865 7 9 7ZM9 12.3333C8.03558 12.3333 7.11065 11.9821 6.4287 11.357C5.74675 10.7319 5.36364 9.88406 5.36364 9C5.36364 8.11595 5.74675 7.2681 6.4287 6.64298C7.11065 6.01786 8.03558 5.66667 9 5.66667C9.96442 5.66667 10.8893 6.01786 11.5713 6.64298C12.2532 7.2681 12.6364 8.11595 12.6364 9C12.6364 9.88406 12.2532 10.7319 11.5713 11.357C10.8893 11.9821 9.96442 12.3333 9 12.3333ZM9 4C5.36364 4 2.25818 6.07333 1 9C2.25818 11.9267 5.36364 14 9 14C12.6364 14 15.7418 11.9267 17 9C15.7418 6.07333 12.6364 4 9 4Z"
                      fill="#787878"
                    />
                  </svg>
                  <p style={{ color: "#787878", marginLeft: "4px" }}>668</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withParams(withTranslation()(Post));
