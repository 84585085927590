import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import "./assets/font.css";
// import "./index.css";
import "./assets/static/index.css";
import "./assets/static/common.css";
import "./assets/min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { createStore } from "redux";
// import rootReducer from "./Reducers";
import { Provider } from "react-redux";
import store from "./stores";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
